import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import styled from "styled-components";
import {
  IoTimer,
  IoSync,
  IoWarning,
  IoPhonePortrait,
  IoCalendar,
  IoPeople,
  IoCloudDownload,
  IoLocation,
} from "react-icons/io5";
import { IoMdOpen } from "react-icons/io";

import { useModal } from "../components/useModal";

import {
  ImageGraphic,
  Breadcrumb,
  CustomerQuote,
  CustomerQuoteLarge,
} from "../components/site";
import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../components/util";
import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import DroppahPayHeroInt from "../images/droppah/DroppahPayHeroInt.svg";
import PayHeroPlusDroppah from "../images/logos/PayHero/PayHeroPlusDroppah.svg";

const SliderCard = styled.div`
  text-align: left;
  padding: 30px;

  h4 {
    color: ${(props) => props.theme.colours.Primary};
    margin-bottom: 7px;
    margin-top: 7px;
  }

  svg {
    font-size: 2.5rem;
    color: ${(props) => props.theme.colours.Primary};
  }

  p {
    margin-bottom: 0;
  }
`;

const AdditionalFeaturesList = styled(Row)`
  --item-gap: 60px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 3) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }

  svg {
    font-size: 2.5rem;
  }

  h4 {
    margin-bottom: 7px;
  }
`;

const Scheduling = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  function getIcon(i) {
    if (i === 1) {
      return <IoCalendar />;
    }
    if (i === 0) {
      return <IoTimer />;
    }
    if (i === 2) {
      return <IoSync />;
    }
  }
  function getTitle(i) {
    if (i === 1) {
      return "Build your ideal employee roster with a single click";
    }
    if (i === 0) {
      return "Stay one step ahead with real-time attendance";
    }
    if (i === 2) {
      return "Sync with PayHero";
    }
  }
  function getDesc(i) {
    if (i === 1) {
      return "Droppah’s AI auto-scheduling takes skills, availability, shift preferences, wage costs, and other requirements into account to automatically build highly optimised rosters in no time.";
    }
    if (i === 0) {
      return "Monitor the day’s attendance & breaks in real-time. Keep a tab on who’s in, who's on break, who’s late and who’s working overtime.";
    }
    if (i === 2) {
      return (
        <>
          <span key={0}>
            Approve time, manage staff rostering, payroll & employee leave with
            deep two-way integration between Droppah and your{" "}
          </span>
          <Link key={1} to={process.env.GATSBY_SITE_URL + '/payroll'}>
            payroll system
          </Link>
          <span key={2}>.</span>
        </>
      );
    }
  }

  const settings = {
    customPaging: function(i) {
      return (
        <SliderCard>
          <div>{getIcon(i)}</div>
          <h4 className="-fontBold">{getTitle(i)}</h4>
          <p>{getDesc(i)}</p>
        </SliderCard>
      );
    },
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  };

  const AdditionalFeatures = [
    {
      icon: <IoWarning />,
      title: "Monitor employee overtime",
      description:
        "Stay on top of your labour costs and monitor employee burnout by easily seeing who's working longer than they should be.",
    },
    {
      icon: <IoPhonePortrait />,
      title: "Manage rosters on the go",
      description:
        "Droppah has been built to work on any device. It’s an easy to use roster app, whether you're at the office, sitting at the bar or on the bus.",
    },
    {
      icon: <IoCalendar />,
      title: "Staff availability",
      description: [
        <span key={0}>Set available days & times and sync leave from </span>,
        <Link key={1} to={process.env.GATSBY_SITE_URL + '/payroll'}>
          payroll
        </Link>,
        <span key={2}>
          {" "}
          to ensure employees are only rostered when they're available.
        </span>,
      ],
    },
    {
      icon: <IoPeople />,
      title: "Delegate roster management",
      description:
        "Organise your business into groups of employees and assign managers to oversee rosters, timesheets and the photo time clock.",
    },
    {
      icon: <IoCloudDownload />,
      title: "Download rosters",
      description:
        "As well as emailing individual shifts to everyone when you publish your roster, we'll also provide you with a PDF to download & print out.",
    },
    {
      icon: <IoLocation />,
      title: "NZ rostering software",
      description:
        "Built by Kiwis for NZ businesses. When you need support you’ll be talking to our friendly Wellington-based team.",
    },
  ];

  return (
    <Layout>
      <Seo
        title="Droppah + PayHero Integration | Rosters & Payroll"
        description="Discover Droppah, staff rostering, time tracking & attendance software for your business. Simplify rostering & pay your staff right."
        pathname={props.location.pathname}
      />
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Breadcrumb
        parentPage="Integrations"
        parentPageLink="/add-ons"
        currentPage="Droppah"
      />
      <Container>
        <Wrapper stackGap={80}>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={30} centerOnMobile>
              <img
                src={PayHeroPlusDroppah}
                alt="PayHero + Droppah Integration"
                height="40"
              />
              <Box stackGap={10}>
                <h1 className="-fontLarge">
                  <span className="-fontPrimary">
                    Rostering & time clock software
                  </span>{" "}
                  for shift-based work
                </h1>
                <h4>
                  Create the perfect employee roster and pay your staff right.
                </h4>
              </Box>
              <FlexButtons>
                <Button to="/signup" className="primary -lg gtm-cta">
                  Get Started with PayHero
                </Button>
                <Button
                  to="https://www.droppah.com"
                  className="grey -lg"
                  atag
                  externalLink
                >
                  Discover Droppah{" "}
                  <IoMdOpen css={{ top: "2px", marginLeft: "5px" }} />
                </Button>
              </FlexButtons>
            </Box>
            <Box size={50}>
              <ImageGraphic variant="1" />
              <Image
                filename="Droppah_ClockAndLive_Hospo.png"
                alt="Droppah + PayHero | Rostering & time clock software"
                rounded
                addShadow
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="black" className="-whiteOut">
        <Wrapper>
          <Box stackGap={40}>
            <h2 className="h1 -textCenter">
              <span className="-fontPrimary">
                Powerful staff scheduling software
              </span>
              <br className="hideOnMobile" /> with all the goods
            </h2>
            <Slider {...settings}>
              <div>
                <Image
                  filename="LiveAttendance_Droppah.png"
                  alt="Real-time Attendance | Droppah"
                  centerImage
                  addShadow
                />
              </div>
              <div>
                <Image
                  filename="AIRostering.png"
                  alt="AI Powered Automatic Rostering | Droppah"
                  centerImage
                  addShadow
                />
              </div>
              <div>
                <Image
                  filename="SendtoPayroll.png"
                  alt="Rostering & Payroll Integration Droppah & PayHero"
                  centerImage
                  addShadow
                />
              </div>
            </Slider>
          </Box>
          <CustomerQuote
            quote="With Droppah, rostering takes two quick steps at the end of the week and we can see exactly where our money is being spent."
            name="Lea Winzer"
            company="Man O' War"
            pic="Lea_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <h2 className="h1 -textCenter">
            <span className="-fontPrimary">
              Staff rostering software & time clock
            </span>
            <br className="hideOnMobile" /> for hourly staff
          </h2>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={7}>
              <h6>Payroll Integration</h6>
              <h3>Sync with payroll for easy staff management</h3>
              <p>
                Approve time and manage staff rostering, payroll & employee
                leave with a deep two-integration between Droppah and PayHero.
                Or, easily export custom CSV files from Droppah to import
                timesheets into any payroll system ready for pay day.
              </p>
            </Box>
            <Box size={50}>
              <img
                className="-center"
                src={DroppahPayHeroInt}
                alt="Rostering & Payroll Integration"
                css={{ width: "75%" }}
              />
            </Box>
          </Row>
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                filename="Droppah_Calendar_DragNDrop.png"
                alt="Roster App | Droppah"
                maxWidth={530}
                addShadow
              />
            </Box>
            <Box size={50} stackGap={7}>
              <h6>Roster App</h6>
              <h3>Perfect rosters, every time</h3>
              <p>
                Add, edit & swap shifts easily with Droppah's drag and drop
                functionality. Make detailed amendments to rosters quickly to
                ensure you have the right people working at the right time
                before publishing.
              </p>
            </Box>
          </Row>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={7}>
              <h6>Time Tracking</h6>
              <h3>Accurately record time worked</h3>
              <p>
                Save on your wage costs by monitoring staff attendance and
                breaks on our photo time clock app. Employees simply take a
                selfie photo when they start and finish their shift which
                creates a timesheet for accurate{" "}
                <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>payroll</Link>.
              </p>
            </Box>
            <Box size={50}>
              <Image
                filename="Droppah_Preview_Timeclock.png"
                alt="Photo Time Clock | Droppah"
                maxWidth={530}
                addShadow
              />
            </Box>
          </Row>
          <CustomerQuoteLarge
            quote="Before Droppah, our rosters were manual and problematic. Integrating PayHero with Droppah has given us the ability to see every shift and what our team is doing in real time."
            name="Brett Pearson"
            company="Eat My Lunch"
            hero="EMLStoryHero.jpg"
            pic="Brett_Circle.png"
            logo="EML_Logo.png"
            videoLink="https://player.vimeo.com/video/721264238"
          />
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                filename="Droppah_Calendar_Coverage.png"
                alt="Forecast Wage Costs | Droppah"
                maxWidth={530}
                addShadow
              />
            </Box>
            <Box size={50} stackGap={7}>
              <h6>Forecast Wage Costs</h6>
              <h3>Control costs & optimise coverage</h3>
              <p>
                Accurately cost staff against sales to ensure you’ve got the
                right coverage at the right times. Reporting tools help you
                control overtime costs by comparing rosters to actual work hours
                and costs.
              </p>
            </Box>
          </Row>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={7}>
              <h6>Employee Communication</h6>
              <h3>Publish rosters & notify employees</h3>
              <p>
                Publish and share rosters with your team. Easily manage shift
                swaps, find replacements and notify employees of shift changes.
                Assign managers to look after rosters and time tracking for
                their own departments.
              </p>
            </Box>
            <Box size={50}>
              <Image
                filename="Droppah_Calendar_Publish.png"
                alt="Publish Rosters & Notify Employees| Droppah"
                maxWidth={530}
                addShadow
              />
            </Box>
          </Row>
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                filename="Droppah_Team.png"
                alt="Manage Multiple Locations or Departments with Teams | Droppah"
                maxWidth={530}
                addShadow
              />
            </Box>
            <Box size={50} stackGap={7}>
              <h6>Flexible Rostering Software</h6>
              <h3>Tailor to your business requirements</h3>
              <p>
                Droppah has the flexibility to work the way you do. Easily
                schedule employees across multiple locations, departments and
                roles. Set up rosters that match your business timetable - be it
                weekly or otherwise.
              </p>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <h2 className="h1 -textCenter">
            The all-in-one roster app you need <br className="hideOnMobile" />
            <span className="-fontPrimary">to make staff scheduling easy</span>
          </h2>
          <AdditionalFeaturesList
            isWrap
            justify="center"
            className="-textCenter"
          >
            {AdditionalFeatures.map((item, i) => {
              return (
                <div>
                  <h3 className="-fontBold -fontPrimary">
                    {item.icon}
                    <br />
                    {item.title}
                  </h3>
                  <p>{item.description}</p>
                </div>
              );
            })}
          </AdditionalFeaturesList>
        </Wrapper>
      </Container>
      <Container className="secondary -textCenter">
        <Wrapper>
          <Box stackGap={50}>
            <div>
              <h2>
                Connect PayHero & Droppah today
                <br />
                <span className="-fontLight">
                  Create the perfect employee roster and pay your staff right.
                </span>
              </h2>
            </div>
            <FlexButtons justify="center">
              <Button to="/signup" className="primary -lg gtm-cta">
                Get Started with PayHero
              </Button>
              <Button
                to="https://www.droppah.com/signup"
                className="dark -lg"
                atag
                externalLink
              >
                Try Droppah for Free{" "}
                <IoMdOpen css={{ top: "2px", marginLeft: "5px" }} />
              </Button>
            </FlexButtons>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Scheduling;
